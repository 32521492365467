@import '../../assets/helper';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
  z-index: 4;
  background: white;
  height: 65px;
  position: sticky;
  top: 0;
  box-shadow: 0px 5px 9px #8f8f8f;
  border-radius: 0px 0px 5px 5px;
  .logo {
    width: 90px;
    display: flex;
    img {
      width: 100%;
      border-radius: 10px;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 15px;

  .logo {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      border-radius: 10px;
    }
  }

  // language
  .languageBtns {
    .select {
      color: $text1;
      font-size: 12px;
      font-weight: 500;
      border: 1px solid #ddd;
      border-radius: 4px;
      outline: none;
      padding: 5px;
      background-color: white;
      cursor: pointer;
      overflow: hidden;

      option {
        background-color: white;
      }

      .active {
        background-color: #ddd;
      }
    }


    .divider {
      background-color: $primary;
      width: 2px;
      height: 22px;
    }
  }
}

// Campony info
.camponyInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
  padding: 8px 16px;
  width:240px;

  .infoItem {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    width: 100%;
    .icon {
      min-width: 20px;
      max-width: 20px;
    }

    .data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $text1;
      font-family: "Roboto","Helvetica","Arial",sans-serif;
      row-gap: 2px;
      .upper {
        font-size: 13px;
        font-weight: bold;
      }

      .lower {
        font-size: 11px;
        font-weight: 500;
      }
    }
  }
  .infoItem.ar {
    flex-direction: row-reverse;
    text-align: right;
    .data {
      align-items: flex-end;
    }
  }
}


.graniteBtn {
  padding: 2px 16px !important;
  &:hover {
    background-color: #46734940 !important;
  }
}
.graniteBtn.ar {
  flex-direction: row-reverse !important;
  & > div {
    text-align: end !important;; 
  }
}
.collapsedMenu {
  a.activeCol {
    text-align: start !important;
    color: rgba(0, 0, 0, 0.82);
    & > div {
      background-color: #46734940;
      padding: 2px 16px !important;
      padding-inline-start: 35px !important;
    }
  }
  a.inactiveCol {
    text-align: start !important;
    color: rgba(0, 0, 0, 0.82);
    & > div {
      padding: 2px 16px !important;
      padding-inline-start: 35px !important;
    }
  }
}
.collapsedMenu.ar {
  a.activeCol ,  a.inactiveCol {
    text-align: end !important;
    & > div {
      padding-inline-end: 35px !important;
    }
  }
}


.itemText {
  color: rgba(0, 0, 0, 0.82);
  font-weight: bold !important;
  text-decoration: none !important
}

a.active {
  color: rgba(0, 0, 0, 0.82);
  font-weight: bold !important;
  text-decoration: none !important;

  .listItem.ar {
    text-align: right !important;
  }

  &>div {
    background-color: #46734960;
    padding: 2px 16px;
  }

  &>div:hover {
    background-color: #46734940;
  }
}

a.inactive {
  color: rgba(0, 0, 0, 0.82);
  font-weight: bold !important;
  text-decoration: none !important;

  .listItem.ar {
    text-align: right !important;
  }
  &>div {
    padding: 2px 16px;
  }
  &>div:hover {
    background-color: #46734940;
  }
}