@import "../../assets/helper";

.container {
  max-width: 1240px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  border-bottom: 1px solid #ddd;
  gap: 15px;

  // logo Container
  .logo {
    cursor: pointer;
    width: 150px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
    }
  }

  // Campony info
  .camponyInfo {
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 15px;

    .infoItem {
      flex: 1;
      display: flex;
      align-items: center;
      column-gap: 10px;

      .icon {
        min-width: 30px;
        max-width: 30px;
      }

      .data {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: $text1;

        .upper {
          font-size: 14px;
          font-weight: bold;
        }

        .lower {
          font-size: 12px;
          font-weight: 600;
          color: #939393;
        }
      }
    }
  }

  // language
  .languageBtns {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    .langText {
      color: $text1;
      font-size: 14px;
      font-weight: bold;
    }

    .select {
      color: $text1;
      font-size: 12px;
      font-weight: 500;
      border: 1px solid #ddd;
      border-radius: 4px;
      outline: none;
      padding: 5px;
      background-color: white;
      cursor: pointer;
      overflow: hidden;

      option {
        background-color: white;
      }

      .active {
        background-color: #ddd;
      }
    }


    .divider {
      background-color: $primary;
      width: 2px;
      height: 22px;
    }
  }
}


@media screen and (max-width: 778px) {
  .container {

  }
}