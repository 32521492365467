.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;

  a {
    width: 40px;
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-end-start-radius: 5px;
    border-start-start-radius: 5px;
    svg {
      height: 30px;
      width: 30px;

      path {
        fill: white
      }
    }
  }

  a:first-child {
    background-color: #3B5998;
  }

  a:nth-child(2) {
    background-color: #81D742;
  }

  a:nth-child(3) {
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  }

  a:nth-child(4) {
    background-color: #ccc;
  }

  a:nth-child(5) {
    background-color: #ff0000;
  }

}