@import '../../assets/helper';

.container {
  height: 60px;
  max-width: 550px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  z-index: 10;
  background-color: white;
  box-shadow: 2px 3px 6px #00000029;
  position: sticky;
  top: 20px;
  border-radius: 10px;
  margin-block: 12px;

  .links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    gap: 10px;
    color: $text1;

    li {
      position: relative;

      .inActive {
        color: $text1;
        font-size: 16px;
        font-weight: 600;
        transition: 0.3s;
        border-bottom: 2px solid transparent;
        border-radius: 0px 0px 2px 2px;
        opacity: 0.8;
        padding-bottom: 5px;

        &:hover {
          opacity: 1;
          color: $primary;
          border-bottom: 2px solid $primary;
        }
      }

      .active {
        font-size: 16px;
        font-weight: 700;
        color: $primary;
        border-bottom: 2px solid $primary;
        border-radius: 0px 0px 2px 2px;
        padding-bottom: 5px;
      }
    }

    li:nth-child(3) ,li:nth-child(2)  {
      height: 60px;
      display: flex;
      align-items: center;

      .active {
        margin-top: 5px;
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .inActive {
        padding-bottom: 0px;
        display: flex;
        align-items: center;
        gap: 4px;
      }

      &:nth-child(3):hover, &:nth-child(2):hover {
        .subMenu {
          display: flex;
        }
      }

      .subMenu {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100px;
        background-color: white;
        display: none;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 10px;
        border-radius: 0px 0px 10px 10px;
        box-shadow: 2px 3px 6px rgb(0 0 0 / 16%);
        z-index: 10;

        li:nth-child(3) , li:nth-child(2) {
          height: unset;
          border: none;
          display: unset;
        }

        li {
          font-size: 14px;
          font-weight: 600;
          color: #707070;
          padding: 5px 10px;
          transition: 0.3s;
          width: 100%;

          a {
            display: flex;
            width: 100%;
            border: 0;
          }

          &:hover {
            background-color: $primary;

            a {
              color: white;
              border-bottom: 0;
            }
          }

          .active {
            border-bottom: 0;
          }
        }
      }
      .subMenu.ar {
        left: unset;
        right: 0;
      }
    }
  }

}

@media screen and (max-width: 530px) {
  .container {
    padding: 20px !important;
  }
}