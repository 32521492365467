@import '../../assets/helper';

.loaderContainer {
  height: 100vh;
  // height: calc(100vh - 166px);
  position: relative;
}
.loader {
  width: 100px;
  height: 100px;
  border-width: 4px;
  border-color: $primary;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -50px;
  margin-left: -50px;
  animation: rotate-right 2s linear infinite;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-width: 4px;
    border-color: $secondary;
    border-style: solid dotted;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    animation: rotate-left 1s linear infinite;
  }
}

@keyframes rotate-right {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes rotate-left {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-1turn);
  }
}